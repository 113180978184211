<template>
  <v-container id="regular-tables-view" fluid tag="section">
    <v-alert
      v-if="alert.visable"
      color="primary"
      dark
      icon="fa-regular fa-hexagon-exclamation"
      border="left"
      prominent
    >
      {{ alert.description }}
    </v-alert>

    <view-intro class="text-start" :heading="document"/>
    <div class="mb-4">
      <v-btn
        color="primary"
        elevation="2"
        outlined
        @click="$router.go(-1)"
      >&lArr; Terug
      </v-btn>
      <!--      <v-btn-->
      <!--        class="ml-4"-->
      <!--        elevation="2"-->
      <!--        icon-->
      <!--        color="blue"-->
      <!--        outlined-->
      <!--        @click="edit.dialog = true;"-->
      <!--      >-->
      <!--        <i class="fa-regular fa-pen"></i>-->
      <!--      </v-btn>-->
      <!--      <v-btn-->
      <!--        class="ml-4"-->
      <!--        elevation="2"-->
      <!--        icon-->
      <!--        color="red"-->
      <!--        outlined-->
      <!--        @click="handleDeletion"-->
      <!--      >-->
      <!--        <i class="fa-regular fa-trash-alt"></i>-->
      <!--      </v-btn>-->
      <v-btn
        class="ml-4"
        elevation="2"
        icon
        color="orange"
        outlined
        @click="createNew.dialog = true"
      >
        <i class="fa-regular fa-plus fa-xl"></i>
      </v-btn>
    </div>
    <material-card
      class="mt-10 elevation-1"
      icon="fa-regular fa-folder"
      icon-small
      :title="document + ' groep'"
      color="accent"
    >
      <v-data-table
        @click:row="goToProductDetail"
        :loading="loading"
        loading-text="Laden even geduld aub"
        :headers="headers"
        :items="products"
        :items-per-page="-1"
      ></v-data-table>
    </material-card>
    <div class="mt-4">
      <v-btn
        color="primary"
        elevation="2"
        outlined
        @click="$router.go(-1)"
      >&lArr; Terug
      </v-btn>
    </div>
    <v-dialog
      v-model="edit.dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h3">Groep aanpassen</span>
        </v-card-title>
        <vue-form-generator ref="editForm" class="px-5 mt-3" :schema="edit.schema" :model="edit.model"
                            :options="formOptions"
        ></vue-form-generator>
        <v-card-text class="text-danger fw-bold">
          {{ edit.dialogAlert }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="edit.dialog = false; edit.dialogAlert = '';"
          >Annuleren
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="handleEditSubmit"
          >Opslaan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="createNew.dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h3">Product aanmaken</span>
        </v-card-title>
        <div class="d-flex text-center justify-center">
          <FormulateInput
            type="image"
            name="headshot"
            label="Selecteer een afbeelding om te uploaden"
            help="Select a png, jpg, or gif to upload."
            validation="mime:image/jpeg,image/png,image/heic"
            @file-upload-complete="saveImage"
            @file-removed="removeImage"
            class="mt-6"
          ></FormulateInput>
        </div>
        <vue-form-generator ref="createNewForm" class="px-5 mt-3" :schema="document === 'abonnementen' ? createNew.subscriptionSchema : createNew.schema" :model="createNew.model"
                            :options="formOptions"
        ></vue-form-generator>
        <v-card-text class="text-danger fw-bold">
          {{ createNew.dialogAlert }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="createNew.dialog = false; createNew.dialogAlert = '';"
          >Annuleren
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="handleNewSubmit"
            :loading="saveLoading"
          >Opslaan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import VueFormGenerator from 'vue-form-generator'

  export default {
    name: 'Producten',
    data () {
      const { document } = this.$route.params
      if (!document) return this.$router.push('/notfound')

      this.$auth.getDocumentProducts(document).then(products => {
        if (!products) return this.$router.push('/products')
        this.products = this.$filter.filterOrders(products)
      }).catch(err => {
        this.alert.visable = true
        this.alert.description = `Er is iets mis gegaan error: ${err} Neem graag contact met beheerder`
      }).finally(() => {
        this.loading = false
      })

      return {
        image: null,
        saveLoading: false,
        createNew: {
          dialogAlert: '',
          dialog: false,
          model: {
            id: '',
            name: '',
            priceNumber: '',
            priceFixer: '',
            section: '',
          },
          schema: {
            fields: [
              {
                type: 'input',
                inputType: 'text',
                label: 'Naam',
                model: 'name',
                min: 3,
                required: true,
                hint: 'Minimaal 3 karakters',
                validator: VueFormGenerator.validators.string,
              },
              {
                type: 'input',
                inputType: 'number',
                label: 'Prijs',
                model: 'priceNumber',
                min: 0,
                required: true,
                validator: VueFormGenerator.validators.number,
              },
              {
                type: 'input',
                inputType: 'text',
                label: 'Prijs fixer',
                model: 'priceFixer',
                validator: VueFormGenerator.validators.string,
              },
              {
                type: 'input',
                inputType: 'text',
                label: 'vak',
                model: 'section',
                validator: VueFormGenerator.validators.string,
              },
            ],
          },
          subscriptionSchema: {
            fields: [
              {
                type: 'input',
                inputType: 'text',
                label: 'Naam',
                model: 'name',
                min: 3,
                required: true,
                hint: 'Minimaal 3 karakters',
                validator: VueFormGenerator.validators.string,
              },
              {
                type: 'input',
                inputType: 'number',
                label: 'Prijs',
                model: 'priceNumber',
                required: true,
                validator: VueFormGenerator.validators.number,
              },
              {
                type: 'input',
                inputType: 'text',
                label: 'Prijs fixer',
                model: 'priceFixer',
                validator: VueFormGenerator.validators.string,
              },
              {
                type: 'input',
                inputType: 'text',
                label: 'vak',
                model: 'section',
                validator: VueFormGenerator.validators.string,
              },
              {
                type: 'input',
                inputType: 'number',
                label: 'Korting percentage',
                model: 'presentage',
                validator: VueFormGenerator.validators.number,
              },
              {
                type: 'input',
                inputType: 'number',
                label: 'Dagen geldig',
                model: 'validForDays',
                validator: VueFormGenerator.validators.number,
              },
            ],
          },
        },
        edit: {
          dialogAlert: '',
          dialog: false,
          model: {
            groupName: document,
          },
          schema: {
            fields: [
              {
                type: 'input',
                inputType: 'text',
                label: 'Groep naam',
                model: 'groupName',
                max: 15,
                required: true,
                hint: 'Maximaal 15 karakters',
                validator: VueFormGenerator.validators.string,
              },
            ],
          },
        },
        formOptions: {
          validateAfterLoad: false,
          validateAfterChanged: true,
          validateAsync: true,
        },
        document,
        headers: [
          {
            text: 'Id',
            value: 'id',
          },
          {
            text: 'Naam',
            value: 'name',
          },
          {
            text: 'Prijs',
            sortable: false,
            value: 'price',
          },
          {
            text: 'Vak',
            value: 'section',
          },
        ],
        products: [],
        loading: true,
        alert: {
          visable: false,
          description: '',
        },
        date: '...',
      }
    },
    methods: {
      saveImage (image) {
        this.image = image.previewData
      },
      removeImage () {
        this.image = null
      },
      handleDeletion () {
        this.$swal.fire({
          title: 'weet u het zeker',
          text: `Wilt u dit group(${this.document}) en alle producten in verwijderen?`,
          icon: 'warning',
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: 'Verwijder!',
          cancelButtonText: 'Annuleren',
        }).then(result => {
          if (!result.isConfirmed) return
          this.$auth.deleteProductDocument(this.document).then(() => {
            this.$swal({
              title: `${this.document} group is successvol verwijderd`,
              icon: 'success',
            })
          }).catch(() => {
              this.$swal({
                title: 'Er is iets mis gegaan met het verwijderen',
                icon: 'error',
                text: 'Neem graag contact op met beheerder',
              })
            },
          ).finally(() => {
            this.$router.push('/products')
          })
        })
      },
      goToProductDetail (e) {
        const productId = e.id
        this.$router.push(`/products/${this.document}/${productId}`)
      },
      async handleEditSubmit () {
        const validationErrors = await this.$refs.editForm.validate()
        if (validationErrors.length !== 0) return
        const { groupName } = this.edit.model
        this.$auth.changeGroupName(this.document, groupName).then(result => {
          if (!result) throw new Error('Could not handle renaming')
          this.document = result
          this.edit.model.groupName = result
          this.$swal({
            title: 'Naam is successvol veranderd',
            icon: 'success',
          })
        }).catch(() => {
            this.$swal({
              title: 'Er is iets mis gegaan met het aanpassen',
              icon: 'error',
              text: 'Neem graag contact op met beheerder',
            })
          },
        ).finally(() => {
          this.edit.dialog = false
        })
      },
      async setProduct (compressedBlob) {
        const status = await this.$auth.setProduct(this.document, this.lastCreatedProduct, compressedBlob)
        this.saveLoading = false
        if (status === 'Product not found') new Error(status)
      },
      async handleNewSubmit () {
        this.saveLoading = true
        const validationErrors = await this.$refs.createNewForm.validate()
        if (validationErrors.length !== 0) return
        const model = this.createNew.model
        if(this.document === 'abonnementen'){
          model.max = 1;
          model.shipLess = true;
        }
        this.$auth.createNewProduct(this.document, model).then(async data => {
          const {documentData, productData} = data;
          if (!documentData) throw new Error('Er is iets mis gegaan bij het maken van een nieuwe product')

          Object.keys(model).forEach(key => model[key] = '')
          this.products = this.$filter.filterOrders(documentData)
          this.createNew.dialog = false
          this.lastCreatedProduct = productData;
          if (this.image) {
            const base64Response = await fetch(this.image)
            const blob = await base64Response.blob()
            await this.$filter.compressProductImage(blob, this.setProduct)
          }
          this.$swal({
            icon: 'success',
            title: 'Succesvol opgeslagen',
          })
          this.saveLoading = false
        }).catch((err) => {
          this.$swal({
            icon: 'error',
            title: 'fout...',
            text: `Er is iets fout gegaan! ${err.message}`,
          })
        })
      },
    },
  }
</script>
